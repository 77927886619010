/********* rac color variables  ********/
/********* rac font variables  ********/
/********* rac BreakPoint variables  ********/
.embeddedServiceHelpButton .helpButton {
  display: none;
  cursor: pointer;
  position: fixed;
  bottom: 91px !important;
  left: 0;
  width: 23px;
  height: auto;
  right: auto;
  transform: rotate(-90deg);
}
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #8dc63f;
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 600;
  min-width: auto;
  height: auto;
  padding: 2px 5px;
  border-radius: 0 0 5px 5px;
  text-decoration: none;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 2px;
}
@media (max-width: 767px) {
  .embeddedServiceHelpButton .helpButton .uiButton {
    font-size: 14px;
  }
}
.embeddedServiceHelpButton .helpButton .uiButton:hover::before,
.embeddedServiceHelpButton .helpButton .uiButton:focus::before {
  border-radius: 0 0 5px 5px;
}
.embeddedServiceHelpButton .helpButton .uiButton .embeddedServiceIcon {
  margin-right: 5px;
}
.embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus::before {
  opacity: 0;
}
.embeddedServiceHelpButton .helpButton .helpButtonEnabled:focus .helpButtonLabel {
  text-decoration: none;
}
h2[embeddedService-chatHeader_chatHeader] {
  color: #ffffff;
}
header.sidebarHeader > button.closeButton,
header.sidebarHeader > button.minimizeButton {
  padding-left: 8px !important;
}
embeddedservice-chat-header lightning-icon,
embeddedservice-chat-header svg.slds-icon-text-default,
svg.slds-icon {
  fill: #ffffff !important;
  width: 20px !important;
}
.chat-now-btn,
#chat-btn,
#chat-agent-btn {
  display: none;
  cursor: pointer;
}
.embeddedServiceSidebarMinimizedDefaultUI {
  background-color: #8dc63f !important;
  border-color: #8dc63f !important;
}
.embeddedServiceSidebarMinimizedDefaultUI.sidebarHeader,
.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer,
.embeddedServiceSidebarMinimizedDefaultUI.minimizedContainer:hover {
  background-color: #8dc63f !important;
  border-color: #8dc63f !important;
  height: 55px !important;
  bottom: 0 !important;
  border: none;
  text-decoration: none !important;
}
.embeddedServiceLiveAgentStateChatInputFooter.chasitorInputWrapper {
  background-color: #ffffff;
}
.embeddedServiceLiveAgentStateChatInputFooter .chasitorControls textarea {
  font-size: 16px;
}
.embeddedServiceLiveAgentStateChatInputFooter .chasitorControls textarea.uiInput {
  font-size: 16px;
  min-height: auto;
  background-color: #f4f4f4;
}
.embeddedServiceSidebar.modalContainer {
  z-index: 9999;
}
.chat-bot-init .embeddedServiceHelpButton .helpButton {
  width: 18px !important;
  height: 34px !important;
  bottom: 182px !important;
}
.chat-bot-init .embeddedServiceHelpButton .helpButton .uiButton {
  background: #8dc63f !important;
  bottom: 0px !important;
  right: 0px !important;
  width: auto !important;
  height: auto !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding: 3px 5px !important;
  border-radius: 0 0 5px 5px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 1px 1px 2px;
}
@media (max-width: 767px) {
  .chat-bot-init .embeddedServiceHelpButton .helpButton .uiButton {
    font-size: 14px;
  }
}
.chat-bot-init .embeddedServiceLiveAgentStateChatInputFooter .chasitorControls textarea.uiInput {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}
.chat-bot-init .embeddedServiceLiveAgentStateChatInputFooter .chasitorControls textarea.uiInput:focus {
  border-color: #0f99d6 !important;
}
.chat-bot-init .embeddedServiceSidebar.layout-docked .dockableContainer {
  bottom: 0 !important;
  right: 20px !important;
}
@media (min-width: 767px) {
  .chat-bot-init .embeddedServiceSidebar.layout-docked .dockableContainer {
    height: 496px;
  }
}
.chat-bot-init .embeddedServiceSidebarMinimizedDefaultUI .minimizedText {
  background: transparent !important;
  text-decoration: none;
}
button.sidebarHeader.minimizedContainer.newMessage.embeddedServiceSidebarMinimizedDefaultUI {
  right: 20px !important;
  bottom: 0px !important;
  border-radius: 8px !important;
  height: 55px !important;
}
@media (max-width: 767px) {
  button.sidebarHeader.minimizedContainer.newMessage.embeddedServiceSidebarMinimizedDefaultUI {
    bottom: 20px !important;
  }
}
embeddedservice-chat-header {
  background-color: #0f99d6 !important;
}
embeddedservice-chat-header h2 {
  font-weight: 800 !important;
  font-family: "Open Sans";
  color: white;
}
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.plaintextContent.chasitor {
  background: #0f99d6;
  filter: drop-shadow(1px 1px 2px #aeadad);
  margin-top: 5px;
  margin-bottom: 5px;
}
.embeddedServiceLiveAgentStateChatPlaintextMessageDefaultUI.plaintextContent.agent {
  background: #ffffff;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.08);
  filter: drop-shadow(1px 1px 3px #aeadad);
  margin-top: 5px;
  margin-bottom: 5px;
}
.embeddedServiceLiveAgentStateChat .messageArea {
  background: #f4f4f4;
}
.embeddedServiceLiveAgentStateChat .messageArea:focus {
  border: none !important;
}
.embeddedServiceSidebarButton.waitingCancelChat {
  background: #0f99d6 !important;
}
.embeddedServiceSidebarButton.waitingCancelChat .label {
  color: #ffffff !important;
}
.embeddedServiceLiveAgentStateChatItem.chatMessage {
  background: transparent !important;
}
embeddedServiceLiveAgentStateChatAction.uiButton svg {
  fill: #0f99d6;
}
